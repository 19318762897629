import React, {Component} from 'react';
import Datetime from 'react-datetime';
import { Row, Column } from 'simple-flexbox';
import { Panel, Button } from "react-bootstrap";

import Alert from "../Alert";
import ComponentHeader from "../../componentHeader";
import Form from "../../../core/Form";
import Select from "../Select";
import Spinner from '../../Spinner';

import PayoutIcon from '../../../assets/images/payouts.png';
import PayoutIconTrustbucks from '../../../assets/images/trustbucks/payouts.png';
import PayoutIconT365 from '../../../assets/images/t365/payouts.png';
import PayoutIconPacificCapital from '../../../assets/images/pacificCapital/payouts.png';

import '../../../assets/css/pacificCapital/payouts.scss';
import '../../../assets/css/t365/payouts.scss';
import '../../../assets/css/trustbucks/payouts.scss';
import '../../../assets/css/payouts.css';

import { crudActions } from "../../../services/crudActions";
import { whiteLabel } from '../../../config';
import { getColor } from '../../../utils/utils.js';

class EmptyScreen extends Component {
  state = {
    controls: new Form({
      pendingPayouts: null,
      availableBalance: null,
      walletBalance: null,
      clientId: "",
      rollingReserveHold: null,
      midId: "",
      balanceToSettle: null,
      withheldFunds: null,
      date: "",
      currency: ""
    }, [{
      name: "currency",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "clientId",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "pendingPayouts",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "availableBalance",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "rollingReserveHold",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "balanceToSettle",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "withheldFunds",
      type: "isNumber",
      rules: {
        required: false
      }
    }, {
      name: "date",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "walletBalance",
      type: "isNumber",
      rules: {
        required: false
      }
    }]),
    lastUpdate: Datetime.moment().utc().format('HH:mm - DD. MMM YYYY'),

    showAlert: false,
    alertType: "success",
    alertMessage: "",
    submitClicked: false,
    loadingLookups: true,
    loadingControls: true,
  };

  componentDidMount() {
    const { controls } = this.state;
    crudActions.get("v1/settlement/lookups").then(
      (lookups) => {
        if (lookups) {
          const uniqueCurrencies = [];
          const midCurrency = lookups.mids.map(mid => {
            return {
              value: mid.id,
              label: mid.currency
            };
          });
          const currency = midCurrency.filter(element => {
            const isDuplicate = uniqueCurrencies.includes(element.label);
        
            if (!isDuplicate) {
              uniqueCurrencies.push(element.label);
        
              return true;
            }
        
            return false;
          });
          currency.sort((elemA, elemB) =>
            elemA.label.localeCompare(elemB.label)
          );
          this.setState({
            merchant: lookups.merchant,
            controls: Object.assign(controls, { clientId: lookups.merchant.value, currency: currency.length ? currency[0].value : "" }),
            mids: lookups.mids.map(elem => {
              return {
                value: elem.id,
                label: elem.name,
                clients: elem.clients,
                pspId: elem.pspId,
                currency: elem.currency
              };
            }),
            loadingLookups: false,
            currency: currency,
          }, () => {
            if (currency.length) {
              this.loadNumbers(currency[0].value);
            }
          });
        }
      }
    ).catch(
      (err) => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      }
    );
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: ""
    });
  };

  onValueChange = (event, field) => {
    const { controls, currency, submitClicked } = this.state;
    const isNumber = controls.fieldRules.find(rule => rule.name === field).type === "isNumber";
    const numberRegex = new RegExp(/^\d+$/);
    if (!event) {
      const matchNumber = event.match(numberRegex);
      controls[field] = isNumber ? parseInt(matchNumber ? matchNumber[0] : controls[field]) : event;
    } else if (event.target) {
      const value = event.target.value.replace(/\./g, "");
      if (value) {
        const matchNumber = value.match(numberRegex);
        controls[field] = isNumber ? parseInt(matchNumber ? matchNumber[0] : controls[field]) : event.target.value;
      } else {
        controls[field] = value;
      }
    } else if (event.value) {
      const matchNumber = typeof event.value === "number" ? [event.value] : event.value.match(numberRegex);
      controls[field] = isNumber ? parseInt(matchNumber ? matchNumber[0] : controls[field]) : event.value;
    } else {
      controls[field] = event.map(elem => elem.value);
    }

    if (field === "currency") {
      this.loadNumbers(controls.currency);

      const pendingPayoutsField = controls.fieldRules.find(rule => rule.name === "pendingPayouts");
      const availableBalanceField = controls.fieldRules.find(rule => rule.name === "availableBalance");
      const rollingReserveHoldField = controls.fieldRules.find(rule => rule.name === "rollingReserveHold");

      pendingPayoutsField.rules.required = false;
      availableBalanceField.rules.required = false;
      rollingReserveHoldField.rules.required = false;
    }
    
    if (field === "balanceToSettle") {
      if (typeof controls.currency !== "number") {
        const currencyValue = currency.find(el => el.label === controls.currency).value;
        controls.currency = currencyValue;
      }
    }

    if (submitClicked) {
      controls.isFormValid();
    }

    this.setState({
      controls
    });
  };

  loadNumbers = (cur) => {
    const { controls, currency } = this.state;
    const currencyLabel = currency.find(el => el.value === cur).label;
    const date = Datetime.moment().format("DD.MM.YYYY");
    this.setState({
      loadingControls: true
    });

    crudActions.get(`v1/settlement/${currencyLabel}?date=${date}`).then(
      (numbers) => {
        if (numbers) {
          const balanceToSettleField = controls.fieldRules.find(fieldRule => fieldRule.name === "balanceToSettle");
          balanceToSettleField.rules.max = numbers.availableBalance;

          this.setState({
            controls: Object.assign(controls, {
              date,
              availableBalance: numbers.availableBalance,
              pendingPayouts: numbers.pendingPayouts,
              rollingReserveHold: numbers.rollingReserveHeld,
              walletBalance: numbers.walletBalance,
              withheldFunds: numbers.withheldFunds
            }),
            loadingControls: false
          });
        }
      }
    ).catch(
      (err) => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      }
    );
  };

  onRequestSettlement = () => {
    const { controls, currency } = this.state;
    const isControlsValid = controls.isFormValid();
    let currencyLabel = "";
    this.setState({
      controls,
      submitClicked: true
    });
    
    if(controls.currency) {
      currencyLabel = currency.find(el => el.value === controls.currency).label;
    } else {
      currencyLabel = "";
    }

    Object.assign(controls, {
      currency: currencyLabel
    });

    if (isControlsValid) {
      crudActions.post("v1/settlement", controls.data()).then(
        () => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: "Settlement successfully requested.",
            controls: Object.assign(controls, {
              pendingPayouts: null,
              availableBalance: null,
              rollingReserveHold: null,
              walletBalance:null,
              currency: "",
              balanceToSettle: null,
              withheldFunds: null,
              date: ""
            })
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        }
      );
    }
  };

  getValue = (value, defaultValue) => {
    if (!value && value !== 0) {
      return defaultValue;
    } else if (value === 0) {
      return 0;
    }

    return value;
  };

  getIcon = () => {
    switch (whiteLabel) {
    case "t365":
      return PayoutIconT365;
    case "trustbucks":
      return PayoutIconTrustbucks;
    case "pacificCapital":
      return PayoutIconPacificCapital;
    default:
      return PayoutIcon;
    }
  };

  render() {
    const { showAlert, alertType, alertMessage, loadingLookups, merchant, controls, loadingControls, currency } = this.state;
    let currencyValue = controls.currency;

    if (typeof currencyValue === 'string' && currency && controls.currency) {

      currencyValue = currency.find(el => el.label === controls.currency).value;
    }
    return (
      <Row flexGrow={ 1 } className={ `${whiteLabel} module apidata` } vertical='start'>
        <Column flexGrow={ 1 }>
          <ComponentHeader
            title="Request Settlement"
            lastUpdate={ this.state.lastUpdate }
            img={ this.getIcon() }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    SELECT
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  { loadingLookups ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : loadingControls && controls.currency ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Merchant </label>
                            <input
                              className={ `form-control ${controls.errors.has('clientId') ? 'error-field' : ""}` }
                              value={ merchant ? merchant.label : "" }
                              disabled
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Currency </label>
                            <Select
                              className={ controls.errors.has('currency') ? 'error-field' : "" }
                              value={ currencyValue }
                              required={ true }
                              clearable={ false }
                              onChange={ (value) => this.onValueChange(value, 'currency') }
                              options={ currency }
                            />
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='end'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Payout Wallet Balance </label>
                            <input
                              className={ `form-control ${controls.errors.has('walletBalance') ? 'error-field' : ""}` }
                              value={ this.getValue(controls.walletBalance, "n/a") }
                              disabled
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Withheld Funds </label>
                            <input
                              className={ `form-control ${controls.errors.has('withheldFunds') ? 'error-field' : ""}` }
                              value={ this.getValue(controls.withheldFunds, 0) }
                              disabled
                            />
                          </Column>
                        </Row>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Pending Payouts </label>
                            <input
                              className={ `form-control ${controls.errors.has('pendingPayouts') ? 'error-field' : ""}` }
                              value={ this.getValue(controls.pendingPayouts, "Select CURRENCY first.") }
                              disabled
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Rolling Reserve Hold </label>
                            <input
                              className={ `form-control ${controls.errors.has('rollingReserveHold') ? 'error-field' : ""}` }
                              value={ this.getValue(controls.rollingReserveHold, "Select CURRENCY first.") }
                              disabled
                            />
                          </Column>
                        </Row>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' alignSelf='start'>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Available Balance </label>
                            <input
                              className={ `form-control ${controls.errors.has('availableBalance') ? 'error-field' : ""}` }
                              value={ this.getValue(controls.availableBalance, "Select CURRENCY first.") }
                              disabled
                            />
                          </Column>
                          <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                            <label> Balance to Request </label>
                            <input
                              className={ `form-control ${controls.errors.has('balanceToSettle') ? 'error-field' : ""}` }
                              value={ this.getValue(controls.balanceToSettle, "") }
                              type="text"
                              disabled={ !controls.currency }
                              onChange={ (value) => this.onValueChange(value, 'balanceToSettle') }
                            />
                          </Column>
                        </Row>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='end'>
                        <Row flexGrow={ 1 } wrap={ true } horizontal='end' vertical='end' alignSelf='end'>
                          <Column flexGrow={ 1 } className="input-column empty-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } className="input-column empty-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 } className="input-column empty-column">
                            <span/>
                          </Column>
                          <Column flexGrow={ 1 }   vertical='end' alignSelf='end' className="input-column" style>
                            <Button
                              type="submit"
                              className="btn defaultBtn"
                              onClick={ () => this.onRequestSettlement() }>
                              SUBMIT
                            </Button>
                          </Column>
                        </Row>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor={ alertType === "success" ? getColor(whiteLabel) : "#DD6B55" }
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default EmptyScreen;
